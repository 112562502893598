@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');
:root {
  --white: 255, 255, 255;
  --black: 48, 86, 145;
  --slate: 51 65 85;
  --dark: 30 41 59;
  --blue: 63 131 248;
  --gray: 17 24 39;
}
.logo img {
  filter: invert(1);
}

element {
  font-size: 24px;
}
@media (min-width: 416px) {
  .icon {
    padding-right: 1rem;
  }
}
:root[theme='dark'] {
  filter: invert(1);
}

:root[theme='dark'] img {
  filter: invert(1);
}
:root[theme='dark'] .logo img {
  filter: invert(1);
}
:root[theme='dark'] iframe {
  filter: invert(1);
}

body {
  box-sizing: border-box;
  font-size: 0.9rem;
  color: rgb(var(--white));
  background-color: rgb(var(--black));
  font-family: 'Moderustic', sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}
.swal2-confirm.swal2-styled {
  background-color: rgb(var(--dark));
}
.userIcon {
  display: none;
}
.sidebar {
  background-color: rgb(var(--black));
}
.dropdown {
  position: relative;
}
.dropdown::after {
  font-family: 'Material Icon';
  content: '⌄';
  padding: 1px;
}
.dropdown .content {
  display: none;
  position: absolute;
  z-index: 100;
}
.dropdown:hover .content {
  display: block;
  background-color: rgb(var(--black));
  padding: 10px 14px;
  box-shadow: 1px 2px rgb(var(--white));
}
.dropdown:hover .content a {
  font-size: 14px;
  color: rgb(var(--white));
  display: block;
  line-height: 22px;
  padding: 14px 17px;
}
.dropdown.content a:hover {
  color: #f2f2f2;
}
#myTab li {
  padding: 2px 3px;
  border-radius: 5px;
}
#myTab li.active {
  background-color: #fff;
  color: black;
}
@media (max-width: 770px) {
  /* .sidebar {
    display: none;
  } */
  .userIcon {
    display: inline-block;
  }
}
