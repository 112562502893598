.tradingview-widget-container iframe {
  height: 100% !important;
}
h2#swal2-title {
  font-size: 20px;
  font-family: 'Lexend';
}
.robot {
  -webkit-filter: invert(1);
  filter: invert(1);
  position: absolute;
  border-radius: 50%;

  width: 60px;
  padding: 5px;
  z-index: 100000;
  bottom: 160px;
  right: 60px;
}
.heart {
  font-size: 150px;
  color: #e00;
  animation: beat 0.25s infinite alternate;
  transform-origin: center;
}
@keyframes beat {
  to {
    transform: scale(1.25);
  }
}
div#swal2-html-container {
  font-size: 16px;
  font-family: 'Heebo';
}
.m-none {
  display: flex;
}
.hidecontrol
  .absolute.left-0.top-0.flex.h-full.items-center.justify-center.px-4,
.hidecontrol
  .absolute.right-0.top-0.flex.h-full.items-center.justify-center.px-4 {
  display: none;
}
.sidenav li > a {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
}
.sidebar {
  position: fixed;
  width: 300px;
  left: 0;
  top: 0;
  margin: 0;
  height: 100%;
  height: calc(100% + 60px);
  height: -moz-calc(100%);
  padding-bottom: 60px;
  z-index: 999;
  overflow-y: auto;
  will-change: transform;
  transition: 2s (0.075, 0.82, 0.165, 1);
  /* -webkit-transform: translateX(-105%); */
  /* transform: translateX(-105%); */
}
.sidebar.hide {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
.sidebar.show {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@media (max-width: 750px) {
  .m-none {
    display: none;
  }
}
@font-face {
  font-family: 'coins';
  src: url(./fonts/coins.ttf);
}

.icon {
  font-family: 'coins', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-crm::before {
  content: '\ea33';
}

.icon-fio::before {
  content: '\ea32';
}

.icon-nut::before {
  content: '\ea30';
}

.icon-eosdt::before {
  content: '\ea31';
}

.icon-ong::before {
  content: '\ea2f';
}

.icon-busd::before {
  content: '\ea2c';
}

.icon-iq::before {
  content: '\ea2d';
}

.icon-lst::before {
  content: '\ea2e';
}

.icon-gas::before {
  content: '\ea2b';
}

.icon-xns::before {
  content: '\ea22';
}

.icon-pyn::before {
  content: '\ea23';
}

.icon-ncash::before {
  content: '\ea24';
}

.icon-loki::before {
  content: '\ea25';
}

.icon-knc::before {
  content: '\ea26';
}

.icon-job::before {
  content: '\ea27';
}

.icon-chz::before {
  content: '\ea28';
}

.icon-btu::before {
  content: '\ea29';
}

.icon-apis::before {
  content: '\ea2a';
}

.icon-rvn::before {
  content: '\ea21';
}

.icon-enj::before {
  content: '\ea20';
}

.icon-iotx::before {
  content: '\ea1f';
}

.icon-ethplo::before {
  content: '\ea1e';
}

.icon-aya::before {
  content: '\ea1d';
}

.icon-yec::before {
  content: '\ea1c';
}

.icon-ankr::before {
  content: '\ea16';
}

.icon-erd::before {
  content: '\ea17';
}

.icon-ftm::before {
  content: '\ea18';
}

.icon-lto::before {
  content: '\ea19';
}

.icon-und::before {
  content: '\ea1a';
}

.icon-vrab::before {
  content: '\ea1b';
}

.icon-atom::before {
  content: '\ea15';
}

.icon-bnbmainnet::before {
  content: '\e912';
}

.icon-eth-ropsten::before {
  content: '\e931';
}

.icon-ropsten::before {
  content: '\e931';
}

.icon-btc-testnet::before {
  content: '\e918';
}

.icon-now-e68::before {
  content: '\e9f8';
}

.icon-awc-986::before {
  content: '\e946';
}

.icon-awc::before {
  content: '\e946';
}

.icon-ont::before {
  content: '\ea14';
}

.icon-kin::before {
  content: '\ea13';
}

.icon-vtho::before {
  content: '\ea12';
}

.icon-vet::before {
  content: '\ea03';
}

.icon-npxs::before {
  content: '\ea0c';
}

.icon-r::before {
  content: '\ea0b';
}

.icon-link::before {
  content: '\ea0e';
}

.icon-hot::before {
  content: '\ea0f';
}

.icon-gusd::before {
  content: '\ea10';
}

.icon-eurs::before {
  content: '\ea11';
}

.icon-trezor::before {
  content: '\ea09';
}

.icon-ledger::before {
  content: '\ea0a';
}

.icon-bth::before {
  content: '\ea08';
}

.icon-bsv::before {
  content: '\ea07';
}

.icon-bchsv::before {
  content: '\ea06';
}

.icon-joys::before {
  content: '\ea05';
}

.icon-tusd::before {
  content: '\ea01';
}

.icon-usdc::before {
  content: '\ea02';
}

.icon-dai::before {
  content: '\ea03';
}

.icon-pax::before {
  content: '\ea04';
}

.icon-aion::before {
  content: '\ea00';
}

.icon-ela::before {
  content: '\e9fe';
}

.icon-smart::before {
  content: '\e9ff';
}

.icon-ely::before {
  content: '\e9fd';
}

.icon-btcp::before {
  content: '\e9f0';
}

.icon-ebst::before {
  content: '\e9f1';
}

.icon-elf::before {
  content: '\e9f2';
}

.icon-hsr::before {
  content: '\e9f3';
}

.icon-iost::before {
  content: '\e9f4';
}

.icon-loom::before {
  content: '\e9f5';
}

.icon-mkr::before {
  content: '\e9f6';
}

.icon-nas::before {
  content: '\e9f7';
}

.icon-now::before {
  content: '\e9f8';
}

.icon-snm::before {
  content: '\e9f9';
}

.icon-synx::before {
  content: '\e9fa';
}

.icon-wan::before {
  content: '\e9fb';
}

.icon-brd::before {
  content: '\e9ea';
}

.icon-mana::before {
  content: '\e9eb';
}

.icon-mer::before {
  content: '\e9ec';
}

.icon-tix::before {
  content: '\e9ed';
}

.icon-xel::before {
  content: '\e9ee';
}

.icon-zen::before {
  content: '\e9ef';
}

.icon-dkk::before {
  content: '\e9e4';
}

.icon-gbp::before {
  content: '\e9e5';
}

.icon-eur::before {
  content: '\e9e6';
}

.icon-usd::before {
  content: '\e9e7';
}

.icon-ukg::before {
  content: '\e9e8';
}

.icon-lrc::before {
  content: '\e9e9';
}

.icon-powr::before {
  content: '\e9e3';
}

.icon-eng::before {
  content: '\e9d1';
}

.icon-ngc::before {
  content: '\e9df';
}

.icon-srn::before {
  content: '\e9e0';
}

.icon-wax::before {
  content: '\e9e1';
}

.icon-dcn::before {
  content: '\e9e2';
}

.icon-gbyte::before {
  content: '\e93c';
}

.icon-cnd::before {
  content: '\e94c';
}

.icon-miota::before {
  content: '\e9cf';
}

.icon-adt::before {
  content: '\e9d0';
}

.icon-via::before {
  content: '\e9cd';
}

.icon-myst::before {
  content: '\e9ce';
}

.icon-cfi::before {
  content: '\e9d2';
}

.icon-dsh::before {
  content: '\e9d3';
}

.icon-xlm::before {
  content: '\e9d4';
}

.icon-fsbt::before {
  content: '\e9d5';
}

.icon-atl::before {
  content: '\e9d6';
}

.icon-ath::before {
  content: '\e9d7';
}

.icon-arn::before {
  content: '\e9d8';
}

.icon-arc::before {
  content: '\e9d9';
}

.icon-apt::before {
  content: '\e9da';
}

.icon-aidoc::before {
  content: '\e9db';
}

.icon-amis::before {
  content: '\e9dc';
}

.icon-adst::before {
  content: '\e9dd';
}

.icon-300::before {
  content: '\e9de';
}

.icon-crpt::before {
  content: '\e9cc';
}

.icon-tip::before {
  content: '\e9cb';
}

.icon-zil::before {
  content: '\e9c0';
}

.icon-xnn::before {
  content: '\e9c2';
}

.icon-viu::before {
  content: '\e9c3';
}

.icon-veri::before {
  content: '\e9c4';
}

.icon-sc::before {
  content: '\e9c5';
}

.icon-rhoc::before {
  content: '\e9c6';
}

.icon-kcs::before {
  content: '\e9c7';
}

.icon-icx::before {
  content: '\e9c8';
}

.icon-ark::before {
  content: '\e9c9';
}

.icon-ae::before {
  content: '\e9ca';
}

.icon-nmr::before {
  content: '\e9bf';
}

.icon-vib::before {
  content: '\e9c1';
}

.icon-stx::before {
  content: '\e9bc';
}

.icon-sbtc::before {
  content: '\e9bd';
}

.icon-rcn::before {
  content: '\e9be';
}

.icon-1st::before {
  content: '\e900';
}

.icon-ada::before {
  content: '\e901';
}

.icon-adc::before {
  content: '\e902';
}

.icon-adx::before {
  content: '\e903';
}

.icon-aeon::before {
  content: '\e904';
}

.icon-amp::before {
  content: '\e905';
}

.icon-anc::before {
  content: '\e906';
}

.icon-ant::before {
  content: '\e907';
}

.icon-arch::before {
  content: '\e908';
}

.icon-ardr::before {
  content: '\e909';
}

.icon-aur::before {
  content: '\e90a';
}

.icon-banx::before {
  content: '\e90b';
}

.icon-bat::before {
  content: '\e90c';
}

.icon-bay::before {
  content: '\e90d';
}

.icon-bc::before {
  content: '\e90e';
}

.icon-bch::before {
  content: '\e90f';
}

.icon-bcn::before {
  content: '\e910';
}

.icon-bft::before {
  content: '\e911';
}

.icon-bnb::before {
  content: '\e912';
}

.icon-bnt::before {
  content: '\e913';
}

.icon-brk::before {
  content: '\e914';
}

.icon-brx::before {
  content: '\e915';
}

.icon-bsd::before {
  content: '\e916';
}

.icon-bta::before {
  content: '\e917';
}

.icon-btc::before {
  content: '\e918';
}

.icon-btcd::before {
  content: '\e919';
}

.icon-btg::before {
  content: '\e91a';
}

.icon-btm::before {
  content: '\e91b';
}

.icon-bts::before {
  content: '\e91c';
}

.icon-clam::before {
  content: '\e91d';
}

.icon-clo::before {
  content: '\e91e';
}

.icon-cloak::before {
  content: '\e91f';
}

.icon-cvc::before {
  content: '\e920';
}

.icon-dao::before {
  content: '\e921';
}

.icon-dash::before {
  content: '\e922';
}

.icon-dcr::before {
  content: '\e923';
}

.icon-dct::before {
  content: '\e924';
}

.icon-dent::before {
  content: '\e925';
}

.icon-dgb::before {
  content: '\e926';
}

.icon-dgd::before {
  content: '\e927';
}

.icon-dgx::before {
  content: '\e928';
}

.icon-dmd::before {
  content: '\e929';
}

.icon-dnt::before {
  content: '\e92a';
}

.icon-doge::before {
  content: '\e92b';
}

.icon-edg::before {
  content: '\e92c';
}

.icon-emc::before {
  content: '\e92d';
}

.icon-eos::before {
  content: '\e92e';
}

.icon-erc::before {
  content: '\e92f';
}

.icon-etc::before {
  content: '\e930';
}

.icon-eth::before {
  content: '\e931';
}

.icon-exp::before {
  content: '\e932';
}

.icon-fc2::before {
  content: '\e933';
}

.icon-fcn::before {
  content: '\e934';
}

.icon-fct::before {
  content: '\e935';
}

.icon-flo::before {
  content: '\e936';
}

.icon-frk::before {
  content: '\e937';
}

.icon-ftc::before {
  content: '\e938';
}

.icon-fun::before {
  content: '\e939';
}

.icon-game::before {
  content: '\e93a';
}

.icon-gbg::before {
  content: '\e93b';
}

.icon-gdc::before {
  content: '\e93d';
}

.icon-gemz::before {
  content: '\e93e';
}

.icon-gld::before {
  content: '\e93f';
}

.icon-gno::before {
  content: '\e940';
}

.icon-gnt::before {
  content: '\e941';
}

.icon-golos::before {
  content: '\e942';
}

.icon-grc::before {
  content: '\e943';
}

.icon-grs::before {
  content: '\e944';
}

.icon-gup::before {
  content: '\e945';
}

.icon-heat::before {
  content: '\e946';
}

.icon-hmq::before {
  content: '\e947';
}

.icon-icn::before {
  content: '\e948';
}

.icon-ifc::before {
  content: '\e949';
}

.icon-incnt::before {
  content: '\e94a';
}

.icon-ioc::before {
  content: '\e94b';
}

.icon-ixt::before {
  content: '\e94d';
}

.icon-jbs::before {
  content: '\e94e';
}

.icon-kmd::before {
  content: '\e94f';
}

.icon-kore::before {
  content: '\e950';
}

.icon-lbc::before {
  content: '\e951';
}

.icon-ldoge::before {
  content: '\e952';
}

.icon-lsk::before {
  content: '\e953';
}

.icon-ltc::before {
  content: '\e954';
}

.icon-lun::before {
  content: '\e955';
}

.icon-maid::before {
  content: '\e956';
}

.icon-mco::before {
  content: '\e957';
}

.icon-mint::before {
  content: '\e958';
}

.icon-mln::before {
  content: '\e959';
}

.icon-mona::before {
  content: '\e95a';
}

.icon-mrc::before {
  content: '\e95b';
}

.icon-msc::before {
  content: '\e95c';
}

.icon-msp::before {
  content: '\e95d';
}

.icon-mtl::before {
  content: '\e95e';
}

.icon-mtr::before {
  content: '\e95f';
}

.icon-mue::before {
  content: '\e960';
}

.icon-nano::before {
  content: '\e961';
}

.icon-nav::before {
  content: '\e962';
}

.icon-nbt::before {
  content: '\e963';
}

.icon-neo::before {
  content: '\e964';
}

.icon-neos::before {
  content: '\e965';
}

.icon-neu::before {
  content: '\e966';
}

.icon-nlg::before {
  content: '\e967';
}

.icon-nmc::before {
  content: '\e968';
}

.icon-note::before {
  content: '\e969';
}

.icon-nvc::before {
  content: '\e96a';
}

.icon-nxt::before {
  content: '\e96b';
}

.icon-oax::before {
  content: '\e96c';
}

.icon-ok::before {
  content: '\e96d';
}

.icon-omg::before {
  content: '\e96e';
}

.icon-omni::before {
  content: '\e96f';
}

.icon-opal::before {
  content: '\e970';
}

.icon-part::before {
  content: '\e971';
}

.icon-pay::before {
  content: '\e972';
}

.icon-piggy::before {
  content: '\e973';
}

.icon-pink::before {
  content: '\e974';
}

.icon-pivx::before {
  content: '\e975';
}

.icon-plbt::before {
  content: '\e976';
}

.icon-plr::before {
  content: '\e977';
}

.icon-plu::before {
  content: '\e978';
}

.icon-pot::before {
  content: '\e979';
}

.icon-ppc::before {
  content: '\e97a';
}

.icon-ptoy::before {
  content: '\e97b';
}

.icon-qcn::before {
  content: '\e97c';
}

.icon-qrk::before {
  content: '\e97d';
}

.icon-qtum::before {
  content: '\e97e';
}

.icon-rads::before {
  content: '\e97f';
}

.icon-rbies::before {
  content: '\e980';
}

.icon-rbt::before {
  content: '\e981';
}

.icon-rby::before {
  content: '\e982';
}

.icon-rdd::before {
  content: '\e983';
}

.icon-rep::before {
  content: '\e984';
}

.icon-rise::before {
  content: '\e985';
}

.icon-rlc::before {
  content: '\e986';
}

.icon-salt::before {
  content: '\e987';
}

.icon-sar::before {
  content: '\e988';
}

.icon-scot::before {
  content: '\e989';
}

.icon-sdc::before {
  content: '\e98a';
}

.icon-sia::before {
  content: '\e98b';
}

.icon-sjcx::before {
  content: '\e98c';
}

.icon-slg::before {
  content: '\e98d';
}

.icon-sls::before {
  content: '\e98e';
}

.icon-sngls::before {
  content: '\e98f';
}

.icon-snrg::before {
  content: '\e990';
}

.icon-snt::before {
  content: '\e991';
}

.icon-start::before {
  content: '\e992';
}

.icon-steem::before {
  content: '\e993';
}

.icon-storj::before {
  content: '\e994';
}

.icon-str::before {
  content: '\e995';
}

.icon-strat::before {
  content: '\e996';
}

.icon-swift::before {
  content: '\e997';
}

.icon-swt::before {
  content: '\e998';
}

.icon-sync::before {
  content: '\e999';
}

.icon-sys::before {
  content: '\e99a';
}

.icon-time::before {
  content: '\e99b';
}

.icon-tkn::before {
  content: '\e99c';
}

.icon-trig::before {
  content: '\e99d';
}

.icon-trst::before {
  content: '\e99e';
}

.icon-trx::before {
  content: '\e99f';
}

.icon-tx::before {
  content: '\e9a0';
}

.icon-ubq::before {
  content: '\e9a1';
}

.icon-unity::before {
  content: '\e9a2';
}

.icon-usdt::before {
  content: '\e9a3';
}

.icon-ven::before {
  content: '\e9a4';
}

.icon-vior::before {
  content: '\e9a5';
}

.icon-vnl::before {
  content: '\e9a6';
}

.icon-vpn::before {
  content: '\e9a7';
}

.icon-vrc::before {
  content: '\e9a8';
}

.icon-vtc::before {
  content: '\e9a9';
}

.icon-waves::before {
  content: '\e9aa';
}

.icon-wings::before {
  content: '\e9ab';
}

.icon-xai::before {
  content: '\e9ac';
}

.icon-xaur::before {
  content: '\e9ad';
}

.icon-xbs::before {
  content: '\e9ae';
}

.icon-xcp::before {
  content: '\e9af';
}

.icon-xdn::before {
  content: '\e9b0';
}

.icon-xem::before {
  content: '\e9b1';
}

.icon-xmr::before {
  content: '\e9b2';
}

.icon-xmr-testnet::before {
  content: '\e9b2';
}

.icon-xpm::before {
  content: '\e9b3';
}

.icon-xrp::before {
  content: '\e9b4';
}

.icon-xtz::before {
  content: '\e9b5';
}

.icon-xvg::before {
  content: '\e9b6';
}

.icon-xzc::before {
  content: '\e9b7';
}

.icon-ybc::before {
  content: '\e9b8';
}

.icon-zec::before {
  content: '\e9b9';
}

.icon-zec-testnet::before {
  content: '\e9b9';
}

.icon-zeit::before {
  content: '\e9ba';
}

.icon-zrx::before {
  content: '\e9bb';
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
